<template>
  <div>
    <el-avatar :size="150" :src="imgUrl" @click.native="editCropper()" @error="true">
      <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
<!--      <img src="http://game-admin.pblab.com/api/pub_upload/2022-03-17/cim2ofrbidtvzu4a2a.jpeg"/>-->
    </el-avatar>
    <div>
      <span v-if="uploadedImg.size">大小： {{ Math.round(uploadedImg.size / 1024) }} KB</span>
    </div>
    <!--    <img v-bind:src="options.img" @click="editCropper()" title="点击上传头像" alt="点击上传头像" class="img-circle img-lg"/>-->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <el-row>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <vue-cropper
              ref="cropper"
              :img="options.img"
              :info="true"
              :autoCrop="options.autoCrop"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :fixedBox="options.fixedBox"
              :outputType="options.outputType"
              :fixedNumber="options.fixedNumber"
              :maxImgSize="options.maxImgSize"
              :enlarge="options.enlarge"
              :infoTrue="options.infoTrue"
              @realTime="realTime"
          />
        </el-col>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <div class="avatar-upload-preview">
            <img :src="previews.url" :style="previews.img"/>
          </div>
          <!--          {{ previews }}-->
        </el-col>
      </el-row>
      <br/>
      <el-row>
        <el-col :lg="2" :md="2">
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
            <el-button size="small">
              上传
              <i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </el-upload>
        </el-col>
        <el-col :lg="{span: 1, offset: 2}" :md="2">
          <el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
        </el-col>
        <el-col :lg="{span: 2, offset: 6}" :md="2">
          <el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {VueCropper} from "vue-cropper";

export default {
  name: 'ArtistAvatar',
  components: {VueCropper},
  props: {
    imgUrl: {
      type: String,
      default() {
        return null
        // return 'http://game-admin.pblab.com/api/pub_upload/2022-03-17/cim2ofrbidtvzu4a2a.jpeg'
      }
    },
    title:{
      type: String,
      default() {
        return '修改KOL头像'
        // return 'http://game-admin.pblab.com/api/pub_upload/2022-03-17/cim2ofrbidtvzu4a2a.jpeg'
      }
    }
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      // 弹出层标题
      options: {
        img: '',//store.getters.avatar, //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true,// 固定截图框大小 不允许改变
        outputType: 'png', // 裁剪生成图片的格式
        fixedNumber: [1, 1], // 截图框的宽高比例
        maxImgSize: 200,//限制图片最大宽度和高度,默认：2000
        enlarge: 1,//图片根据截图框输出比例倍数
        mode: '105px 105px',  //图片默认渲染方式
        infoTrue: true,  //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},
      fileType: '',
      uploadHeaders: {},
      uploadData: {},
      uploadToken: {
        accessid: '',
        host: '',
        policy: '',
        signature: '',
        expire: 0,
        callback: '',
        dir: '',
        upload_save: '',
        filename: '',
      },
      uploadedImg: {},
      previewed: false
    };
  },
  methods: {
    // https://github.com/xyxiao001/vue-cropper
    // 编辑头像
    editCropper() {
      this.open = true;
    },
    // 覆盖默认的上传行为
    requestUpload(e) {
      console.log('e', e)
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },

    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
        reader.onload = () => {
          // console.log('reader.result', reader.result)
          this.options.img = reader.result
        };

        // console.log('this.$refs.cropper',this.$refs.cropper.getAttribute('src'))

      }
    },
    //base64转为图片
    dataURLtoFile(base64Img, filename = 'file') {
      let arr = base64Img.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },

    //裁剪为圆形
    clipImg(image) {
      //改为圆形
      // let image = new Image();
      // image.src = base64;
      // const width = this.options.autoCropWidth || 200;
      // const height = this.options.autoCropHeight || 200;
      const width = image.width
      const height = image.height

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      // console.log(`w:${width},h:${height}`)
      // context.fillStyle="#0000ff";
      canvas.width = width;
      canvas.height = height;
      const circle = {
        x: width / 2,
        y: height / 2,
        r: width / 2
      };
      context.clearRect(0, 0, width, height);
      // //开始路径画圆,剪切处理
      context.save();
      context.beginPath();
      context.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, false);
      context.clip(); //剪切路径
      context.drawImage(image, 0, 0, width, height, 0, 0, width, height);
      let imgBase64 = canvas.toDataURL("image/png", 0.8)
      //恢复状态
      context.restore();
      //显示图片（预览）
      this.showPreImg(imgBase64)
      return imgBase64
    },
    showPreImg(imgBase64) {
      // var img = document.getElementById("img").children[0];
      // img.src = event.target.result;//base64
      this.options.img = imgBase64
    },

    //文件上传到服务端
    async fileUpload(file, file_name) {
      let formData = new FormData();

      await this.setOssParams()
      let ossUrl = false
      if (this.uploadToken.upload_save == 'oss') {
        ossUrl = this.uploadToken.host
        formData.append('key', this.uploadData.key)
        formData.append('policy', this.uploadData.policy)
        formData.append('OSSAccessKeyId', this.uploadData.OSSAccessKeyId)
        formData.append('success_action_status', '200')
        formData.append('callback', this.uploadData.callback)
        formData.append('signature', this.uploadData.signature)
      }
      formData.append('file', file, file_name)
      this.$api.editorUploadFile(formData, ossUrl).then((res) => {
        this.open = false;
        this.uploadedImg = res[0]
        this.options.img = res[0] ? res[0].full_path : ''
        this.$emit('uploaded', res[0])
        this.msgSuccess('上传成功')
        this.$refs.cropper.clearCrop();
      }).catch(() => {
        this.msgError('上传失败')
        this.$refs.cropper.clearCrop();
      })
    },
    // 上传图片
    async uploadImg() {
      this.$refs.cropper.getCropBlob(async (data) => {

        let type = data.type
        let types = {
          "image/jpeg": "jpeg",
          "image/png": "png"
        }
        let file_name = "temp.jpg"
        if (types[type]) {
          file_name = "temp." + types[type]

          const suffix_index = file_name.lastIndexOf(".")
          this.fileType = file_name.substring(suffix_index)
        }
        // file -> base64 -> image -> canvas裁剪->file ->upload file
        // 上传服务端的时候裁剪的原因：1）vue-cropper无法裁剪圆形图片
        let reader = new FileReader();
        reader.readAsDataURL(data);//读取图像文件 result 为 DataURL, DataURL 可直接 赋值给 img.src
        reader.onload = (event) => {
          //显示图片（预览）
          this.showPreImg(event.target.result)
          let newImg = new Image();
          newImg.src = event.target.result
          newImg.onload = () => {

            let dataUrl = this.clipImg(newImg)//Image裁剪成圆形，返回图片base64
            let file = this.dataURLtoFile(dataUrl)//图片base64转文件
            this.fileUpload(file, file_name) //上传到服务端
          }
        }
      });
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    imgLoad(data) {
      console.log('data', data)
      console.log('cropW', this.$refs.cropper.cropW)
      console.log('cropH', this.$refs.cropper.cropH)
    },
    // 设置头像base64
    setAvatarBase64(src, callback) {

      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + '?v=' + Math.random();
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        // console.log('image', image)
        let base64 = _this.transBase64FromImage(image);
        callback && callback(base64);
      }
    },
    // 将网络图片转换成base64格式
    transBase64FromImage(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // let canvas = this.getRoundedCanvas(image)
      // 可选其他值 image/jpeg
      return canvas.toDataURL("image/png");
    },
    /**
     * 把图片裁剪成圆形
     * @param sourceCanvas
     * @returns {HTMLCanvasElement}
     */
    getRoundedCanvas(sourceCanvas) {
      // console.log('s', sourceCanvas)
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const width = sourceCanvas.width;
      const height = sourceCanvas.height;
      canvas.width = width;
      canvas.height = height;
      const circle = {
        x: width / 2,
        y: height / 2,
        r: width / 2
      }
      context.clearRect(0, 0, width, height);
      // //开始路径画圆,剪切处理
      context.save();
      context.beginPath();
      context.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, false);
      context.clip(); //剪切路径
      context.drawImage(sourceCanvas, 0, 0);
      // //恢复状态
      context.restore();
      return canvas;
    },

    /**
     * 获取上传凭证
     */
    async getOssToken() {
      this.uploadToken = await this.$api.getOssToken();
    },
    /**
     * 设置上传参数
     */
    async setOssParams() {


      await this.getOssToken()
      let fileKey = this.uploadToken.dir + this.uploadToken.filename;
      if (this.fileType) {
        fileKey += this.fileType
      }
      if (this.uploadToken.upload_save == 'oss') {
        this.uploadData = {
          key: fileKey,
          policy: this.uploadToken.policy,
          OSSAccessKeyId: this.uploadToken.accessid,
          success_action_status: "200", //让服务端返回200,不然，默认会返回204
          callback: this.uploadToken.callback,
          signature: this.uploadToken.signature
        }
      }
      if (this.uploadToken.upload_save == 'local') {
        this.uploadHeaders = {
          'HYPERF-SESSION-ID': localStorage.getItem("HYPERF_SESSION_ID")
        }
        this.uploadData = {
          upload_path: this.uploadToken.dir
        }
      }
    },


  },
  watch: {
    imgUrl(val) {
      if (val) {
        this.setAvatarBase64(val, (base64) => {
          this.options.img = base64;
        });
      }
    }
  },
  created() {
    if (this.imgUrl) {
      this.options.img = ''
      this.setAvatarBase64(this.imgUrl, (base64) => {
        this.options.img = base64;
      });
    }

  }
}
</script>
<style scoped>
/* image */
.img-circle {
  border-radius: 50%;
}

.img-lg {
  width: 120px;
  height: 120px;
  /*border-radius: 50%;*/
  /*box-shadow: 0 0 4px #ccc;*/
  /*overflow: hidden;*/
}

.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}

.image-slot {
  width: 100%;
  height: 100%;
  border: #00feff 1px solid;
  font-size: 48px;
}
</style>

