<template>
  <div>
    <el-card class='box-card' style="height: 340px">
      <div slot="header" class="clearfix">
        <b style="font-size: 16px">自定义面板</b>
        <el-link style="float: right" @click="handleEdit">编辑</el-link>
      </div>
      <div style="height: 280px;overflow: scroll">
        <div class="contentBox">
          <div v-for="(item,index) in dataList"
               :key="index">
            <div style="margin-right: 10px">
              <div v-if="item.type==='href'" class="href">
                <svg-icon style="font-size: 12px;display:inline-block " :icon-class="item.icon"></svg-icon>
                <el-link :target="item.open" :href="item.url">{{ item.title }}</el-link>
                <i style="margin-left: 4px;font-size: 12px;cursor: pointer" class="el-icon-close"
                   @click="handleRevoke(index)"></i>
              </div>
            </div>
          </div>

        </div>
        <div class="contentBox">
          <div v-for="(item,index) in dataList" :key="index" style="margin-top: 10px;width: 100%;overflow: hidden">
            <el-card v-if="item.type==='content'" shadow="never">
              <b>{{ item.title }} </b>
              <i style="margin-left: 4px;font-size: 14px;cursor: pointer;float: right" class="el-icon-close"
                 @click="handleRevoke(index)"></i>
              <br/>
              <span>{{ item.content }}</span>
              <p style="float: right">{{ item.time }}</p>
            </el-card>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog :title='dialogTitle' width='800px' :visible.sync="dialogVisible" center>
      <el-form ref='formData' :model='formData' :rules='rules' label-poionsit='right' label-width='80px'>
        <el-form-item label='内容类型' prop='type'>
          <el-radio-group v-model="formData.type">
            <el-radio :label="'content'">内容</el-radio>
            <el-radio :label="'href'">超链接</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label='名称' prop='title'>
          <el-input v-model='formData.title' maxlength='10' show-word-limit clearable></el-input>
        </el-form-item>
        <template v-if="formData.type==='content'">
          <el-form-item label='内容' prop='title'>
            <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 10}"
                placeholder="请输入内容"
                v-model="formData.content">
            </el-input>
          </el-form-item>
          <el-form-item label='时间'>
            <el-date-picker
                v-model="formData.time"
                type="datetime"
                placeholder="选择日期时间"
                align="right"
                value-format='yyyy-MM-dd hh:mm'
            >
            </el-date-picker>
          </el-form-item>
        </template>
        <template v-if="formData.type==='href'">
          <el-form-item label='链接地址' prop='url'>
            <el-input v-model='formData.url' placeholder="请输入http/https开头的链接" maxlength='300' show-word-limit
                      clearable></el-input>
            <span
                style="font-size: 12px;line-height: 2px">站外网址需要输入http/https开头的链接,如星象仪站内地址需要.com/后的地址，如：/user/profile</span>
          </el-form-item>
          <el-form-item label='打开方式' prop='open'>
            <el-radio-group v-model="formData.open">
              <el-radio :label="'_blank'">新窗口打开</el-radio>
              <el-radio :label="''">当前页面打开</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='图标' prop='url'>
            <el-select v-model="formData.icon" placeholder="选择icon">
              <template slot="prefix">
                <svg-icon style="font-size: 22px" :icon-class="formData.icon||'shouye'"></svg-icon>
              </template>
              <el-option :value="i" v-for="i in svgList" :key="i">
                <svg-icon style="font-size: 30px" :icon-class="i"></svg-icon>
              </el-option>
            </el-select>
          </el-form-item>
        </template>

      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button size='small' @click='dialogVisible=false'>取消</el-button>
        <el-button size='small' type='primary' @click='handleStore'>{{ formData.href ? '保存' : '添加' }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const rules = {
  href: [
    { required: true, message: '请输入url', trigger: 'blur' }
  ]
}
export default {
  name: 'CustomJump',
  data() {
    return {
      dialogVisible: false,
      formData: {
        type: 'content',
        title: null,
        url: null,
        open: '_blank'
      },
      rules: rules,
      dataList: [],
      dialogTitle: '添加自定义内容',
      pagingData: {
        page_size: 5,
        current_page: 1,
        total: 0
      },
      svgList: ['dashboard', 'chart', 'vote', 'zhibo', 'dianpu', 'huizong', 'yuehuizong', 'bangdan', 'yingshou', 'fenxi'],
      activeName: 'content'
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleRevoke(i) {
      this.$confirm('确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDelHref(i)
      })
    },
    handleEdit() {
      this.dialogTitle = '添加自定义内容'
      this.dialogVisible = true
    },
    handleStore() {
      this.dataList.push(JSON.parse(JSON.stringify(this.formData)))
      this.saveData()

      this.dialogVisible = false
    },
    async saveData() {
      // localStorage.setItem('lbPreviewRow', JSON.stringify(this.configData))
      let data = {
        type: 'custom_url',
        settings: JSON.stringify(this.dataList)
      }
      await this.$api.saveMySetting(data)
    },
    handleDelHref(i) {
      this.dataList.splice(i, 1)
      this.saveData()

    },
    async getUserSetting() {
      let data = {
        type: 'custom_url',
        user_id: this.userInfo.id
      }
      let { info } = await this.$api.getMySetting(data)
      const tableSet = info.settings ? JSON.parse(info.settings) : null
      if (tableSet) this.dataList = tableSet
    }
  },
  mounted() {
    // let customUrl = localStorage.getItem('custom_url')
    // if (customUrl) {
    //   this.dataList = JSON.parse(customUrl)
    // }
    this.getUserSetting()
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__header {
  padding: 20px !important;

}

.contentBox {

  .href {
    margin-right: 10px;
    border: 1px dotted #DCDFE6;
    padding: 2px;
    display: inline-block;
    float: left
  }
}
</style>
