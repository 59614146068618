<template>
  <div>
    <el-link type='primary' @click='showMyPermissions'><i class='el-icon-s-promotion'></i>我的权限
      <i :class="[showPermissions?'el-icon-arrow-up':'el-icon-arrow-down']"></i>
    </el-link>
    <div class='tag-group' v-if='showPermissions' style='padding: 10px;margin-bottom: 10px'>
      <!--              <el-input icon='el-icon-search' placeholder='搜索权限' clearable>-->
      <!--                <template slot='append'>-->
      <!--                  <el-button>搜索</el-button>-->
      <!--                </template>-->
      <!--              </el-input>-->
      <!--    <div style='padding: 10px'>-->
      <!--      <el-tag-->
      <!--        v-for='(item,index) in userPermissions'-->
      <!--        :key='item'-->
      <!--        :type='item'-->
      <!--        effect='plain' style='margin-right: 5px;margin-bottom: 5px'>-->
      <!--        {{ index + 1 }}）{{ item }}-->
      <!--      </el-tag>-->
      <!--    </div>-->
      <div style='width: 100%;height: 50vh'>
        <el-tree
          class='tree-border'
          :data='permissionsList'
          :render-after-expand='false'
          show-checkbox
          ref='refPermissionsList'
          node-key='id'
          empty-text='加载中，请稍后'
          :props='permissionProps'
          highlight-current
        ></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MyPermissionPanel',
  data() {
    return {
      showPermissions: false,
      permissionsList: [],
      checkedIds: [],
      permissionProps: {
        children: 'children',
        label: 'display_name'
      },
      rolePermissions: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  methods: {
    showMyPermissions() {
      this.showPermissions = !this.showPermissions
      this.getPermissionNodes()
    },
    //检测id是否是父级id，并将子级id过滤，运用递归
    checked(name, data, newArr) {
      data.forEach(item => {
        if (item.name === name) {
          if (item.children === undefined || item.children.length < 1) {
            newArr.push(item.id)
          }
        } else {
          if (item.children !== undefined && item.children.length !== 0) {
            this.checked(name, item.children, newArr)
          }
        }
      })
    },
    async getPermissionNodes() {
      ///admin_api/permissions/list
      let { list } = await this.$api.getPermissionsList()
      this.permissionsList = list
      this.checkedIds = []
      this.userPermissions.forEach(item => {
        this.checked(item, this.permissionsList, this.checkedIds)
      })
      // console.log('checkedIds', this.checkedIds)
      this.$nextTick(() => {
        if (this.$refs.refPermissionsList) {
          this.$refs.refPermissionsList.setCheckedKeys(this.checkedIds)
        }

      })
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.getPermissionNodes()
    // }, 500)
  }
}
</script>

<style scoped>

</style>
