<template>
  <el-card class='box-card'>
    <div slot='header' class='clearfix'>
      <b style='font-size: 16px;cursor: pointer' @click='loadTodoSummary' title="刷新待办">我的待办
        <!--        <el-link  icon='el-icon-refresh' @click='loadTodoSummary'></el-link>-->
      </b>
    </div>
    <el-row class='messageBox' :gutter='20'>
      <el-col class='colClass' :span='8' v-for='(item,key) in messageMap' :key='key'
              @click.native='goToPage(item.routerName)'>
        <el-badge :value='item.news' class='badge' :max='99' :hidden='item.news==0'>
          <svg-icon class='svgCol' :style='{ color: item.color }' :icon-class="item['icon']"></svg-icon>
        </el-badge>
        <span>{{ item.name }}</span>

      </el-col>
    </el-row>
  </el-card>

</template>

<script>
export default {
  name: 'userMessage',
  data() {
    return {
      // messageType: [
      //   { name: '系统公告', icon: 'gonggao', color: 'rgb(31, 218, 202)', news: 0, routerName: 'notify' },
      //   { name: '权限申请', icon: 'permissions', color: 'rgb(191, 12, 44)', news: 10, routerName: 'role_apply_record' },
      //   { name: 'OA审批', icon: 'OA', color: 'rgb(225, 133, 37)', news: 10 },
      //   { name: '选品提醒', icon: 'gonggao', color: 'rgb(63, 178, 127)', news: 0 },
      //   { name: '', icon: '', color: 'rgba(77, 175, 27, 0.79)', news: 0 },
      //   { name: '', icon: '', color: 'rgb(0, 216, 255)', news: 0 }
      // ],
      messageMap: {
        notify: { name: '系统公告', icon: 'gonggao', color: 'rgb(31, 218, 202)', news: 0, routerName: 'notify' },
        role_apply: {
          name: '权限申请',
          icon: 'permissions',
          color: 'rgb(191, 12, 44)',
          news: 0,
          routerName: 'permissionList'
        },
        oa: { name: 'OA审批', icon: 'OA', color: 'rgb(225, 133, 37)', news: 0 },
        lb_p: { name: '选品提醒', icon: 'gonggao', color: 'rgb(63, 178, 127)', news: 0 }
      }

    }
  },
  methods: {
    async loadTodoSummary() {
      let { role_apply_count, oa_count, lb_p_count } = await this.$api.geTodoSummary()
      this.messageMap.role_apply.news = role_apply_count
      this.messageMap.oa.news = oa_count
      this.messageMap.lb_p.news = lb_p_count
    },

    goToPage(name) {
      this.$router.push({ name: name })
    }
  },
  created() {
    this.loadTodoSummary()
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-card__body {
  padding: 0 !important;
}

::v-deep .el-card__header {
  border-bottom: none;
}

.colClass {
  height: 100px;
  //line-height: 100px;
  //border: 1px solid rgba(0, 0, 0, 0.88);
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  text-align: center;
  cursor: pointer;

  > span {
    display: block;
    margin-top: 10px;
  }

  .svgCol {
    font-size: 30px;
    display: block;
    //padding-top: 16px;
  }

  .badge {
    position: relative;
    left: 0;
    //top: 20px;
    margin-top: 16px;


  }


  &:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    transition: 1s;
  }
}
</style>
