module.exports = [
  'Skull',
  'SkullOutline',
  'Bat',
  'Cumbia',
  'Deer',
  'Diamond',
  'Hola',
  'Selena',
  'Pizza',
  'Resist',
  'Bear'
]
