<template>
  <div>
    <el-row v-if="navType==='old'" :gutter='20' style='flex-wrap: wrap !important;  display:flex;'>
      <el-col :span='8' v-for='(v ,k) in navMenus' :key='k'>
        <template class='box-card'>
          <el-card shadow='hover' style='margin-bottom: 5px'>
                             <span style='font-size: 14px;font-weight: bold' v-if='v.children&&v.children.length>0'>
                              {{ v.display_name }}({{ v.children ? v.children.length : '' }})：
                             </span>
            <template v-if='(v.children && v.children.length)'>
              <el-link v-for='(item, indx) in v.children' :index="item['url'].toString()" :key="k + '-' + indx"
                       type='primary' style='padding:3px 5px' @click='goToPage(item.url,item)'
                       :disabled="v['permission_name'] &&!(userPermissions.indexOf(item['permission_name']) > -1)"
              >
                <svg-icon :fill='fillColor' style='display: inline-block' :icon-class="item['icon']"></svg-icon>
                <span :title="item['url']">{{ item['display_name'] }}</span>
              </el-link>
            </template>
            <template v-else>
              <el-link type='primary' @click='goToPage(v.url,v)'
                       :disabled="v['permission_name'] &&!(userPermissions.indexOf(v['permission_name']) > -1)">
                <svg-icon :fill='fillColor' style='display: inline-block' :icon-class='v.icon'></svg-icon>
                <span :title='v.url'>
                                  {{ v.display_name }}
                                </span>
              </el-link>
            </template>
          </el-card>
        </template>
      </el-col>
    </el-row>
    <div v-else>
      <div v-for='(v ,k) in navMenus' :key='k' class="nav">
        <div class="boxTitle">
                <span style='font-size: 14px;font-weight: bold' v-if='v.children&&v.children.length>0'>
                        {{ v.display_name }}({{ v.children ? v.children.length : '' }})：
                       </span>
          <span style='font-size: 14px;font-weight: bold' v-else>{{ v.display_name }}：</span>
        </div>
        <div class="navBox">
          <template v-if='(v.children && v.children.length)'>
            <el-link v-for='(item, indx) in v.children' :index="item['url'].toString()" :key="k + '-' + indx"
                     class="navItem"
                     :class="v['permission_name'] &&!(userPermissions.indexOf(item['permission_name']) > -1)?'disabled':''"
                     type='primary' style='padding:3px 5px' @click='goToPage(item.url,item)'
                     :disabled="v['permission_name'] &&!(userPermissions.indexOf(item['permission_name']) > -1)"
            >
              <svg-icon :style="{ color: setColor(indx) }" style='display: inline-block'
                        :icon-class="item['icon']"></svg-icon>
              <span :title="item['url']">{{ item['display_name'] }}</span>
            </el-link>
          </template>
          <template v-else>
            <el-link type='primary' @click='goToPage(v.url,v)'
                     class="navItem"
                     :class="v['permission_name'] &&!(userPermissions.indexOf(v['permission_name']) > -1)?'disabled':''"
                     :disabled="v['permission_name'] &&!(userPermissions.indexOf(v['permission_name']) > -1)">
              <svg-icon :style="{ color: setColor(k) }" style='display: inline-block' :icon-class='v.icon'></svg-icon>
              <span :title='v.url'>
                              {{ v.display_name }}
                            </span>
            </el-link>
          </template>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import myNav from '@/json/menus.json'

export default {
  name: 'MyMenus',
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'isCollapse'])
  },
  props: {
    navType: {
      type: String,
      default() {
        return 'new'
      }
    }
  },
  data() {
    return {
      navMenus: myNav.slice(1, this.length),
      colorArray: ['rgb(208, 48, 80)', 'rgb(31, 218, 202)', 'rgb(191, 12, 44)', '#7b84e6', 'rgb(63, 178, 127)', 'rgba(77, 175, 27, 0.79)', 'rgb(225, 133, 37)', 'rgb(0, 216, 255)']

    }
  },
  methods: {
    goToPage(routeName, menu) {
      this.$utils.jumpPage(this, routeName, menu)
    }, setColor(k) {
      if (this.colorArray?.[k]) {
        return this.colorArray[k]
      } else {
        return this.colorArray[Math.ceil(k / 2)]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  border: 1px dotted #EBEEF5;
  margin: 10px 0;
  border-radius: 10px;

  .boxTitle {
    color: #909399;
    display: inline-block;
    width: 130px;
    padding-left: 10px;
    text-align: right;
  }

  .navBox {
    //display: flex;
    flex-wrap: wrap;
    width: 100%;
    display: inline-block;

    .navItem {
      margin: 10px 10px;
      border: 1px solid #DCDFE6;
      width: 190px;
      height: 30px;
      border-radius: 6px;
      color: black;
      transition: 0.5s;

    }

    .navItem:hover {
      background-color: #e8e8e9;
    }

    .disabled {
      //border: 1px solid red;
      background-color: #e9e9eb;
      color: #bcbec2;
    }
  }


}
</style>
