<template>
  <div>
    <el-card v-loading="loading" style="height: 254px">
      <div slot="header" class="clearfix">
        <b style="font-size: 16px">快捷导航 &nbsp;</b>
        <el-link style="float: right" @click="handleEdit">编辑</el-link>

      </div>
      <el-row>
        <el-col @click.native='goToPage(item.url,item)' :span="4" v-for="(item, k) in hasPermissions" :key="k"
                class="colClass">
          <svg-icon class="svgCol" :style="{ color: setColor(k) }" :icon-class="item['icon']"></svg-icon>
          <span>{{ item.display_name }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog title='编辑展示的快捷导航' width='680px' :visible.sync="dialogVisible" center>
      <el-row :gutter='20' style="max-height: 520px;overflow-y: scroll;padding: 0 10px ">
        <el-col @click.native="handleSelect(item)" class="colClass" :span="4" v-for="(item,i) in allHasPermissions"
                :key="i">
          <el-badge class="badge" v-show="isIncloud(item)" is-dot>
          </el-badge>
          <svg-icon class="svgCol" :icon-class="item['icon']"></svg-icon>

          <span>{{ item.display_name }}</span>

        </el-col>

      </el-row>
      <span slot='footer' class='dialog-footer'>
        <el-button size='small' @click='dialogVisible=false'>取消</el-button>
        <el-button size='small' type='primary' @click='handleStore'>保存({{ selectData.length }})</el-button>
    </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import myNav from '@/json/menus.json'
import _ from 'lodash'

export default {
  name: 'MyMenus',
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'isCollapse', 'userInfo'])
  },
  // props:{
  //   navMenus:{
  //     type:Array
  //   }
  // },
  data() {
    return {
      navMenus: myNav.slice(1, this.length),
      colorArray: ['rgb(31, 218, 202)', 'rgb(191, 12, 44)', 'rgb(63, 178, 127)', 'rgba(77, 175, 27, 0.79)', 'rgb(225, 133, 37)', '#d03050', 'rgb(0, 216, 255)'],
      hasPermissions: [],
      allHasPermissions: [],
      newNav: [],
      loading: false,
      dialogVisible: false,
      selectData: []
    }
  },
  methods: {
    goToPage(routeName, menu) {
      // console.log(routeName)
      this.$utils.jumpPage(this, routeName, menu)
    },
    findPermissionsNav() {
      this.loading = true
      //找到9个菜单
      // console.log(this.navMenus, this.userPermissions)
      let newNav = []
      let copyNavMenus = JSON.parse(JSON.stringify(this.navMenus))
      copyNavMenus.forEach((item) => {
        if (item?.children) {
          newNav = [...newNav, ..._.flattenDeep(item.children)]
          delete item.children

        } else
          newNav.push(item)
      })

      //因为只要展示9个-所以截取前9个
      this.allHasPermissions = newNav
          .map((item) => {
            return this.userPermissions.indexOf(item['permission_name']) > -1 ? item : false
          })
          .filter(Boolean)
      this.newNav = newNav
      this.loading = false
      //   userPermissions.indexOf(item['permission_name']) > -1
    },
    setColor(k) {
      if (this.colorArray?.[k]) {
        return this.colorArray[k]
      } else {
        return this.colorArray[Math.ceil(k / 2)]
      }
    },
    handleEdit() {
      this.findPermissionsNav()
      this.selectData = JSON.parse(JSON.stringify(this.hasPermissions))
      this.dialogVisible = true
    },
    handleStore() {
      if (this.selectData.length > 12) {
        this.$message.error('菜单不得大于12个')
      }else{
        this.hasPermissions = this.selectData
        this.saveData()
        this.dialogVisible = false

      }

    },
    handleSelect(item) {
      let index = this.selectData.findIndex(function(o) {
        return String(o.permission_name) ===  String(item.permission_name)
      })
      if (index < 0) {
        this.selectData.push(item)
      } else
        this.selectData.splice(index, 1)

    },
    isIncloud(item) {
      let index = this.selectData.findIndex(function(o) {
        return String(o.permission_name) === String(item.permission_name)
      })
      return index !== -1
    },
    async saveData() {
      // localStorage.setItem('lbPreviewRow', JSON.stringify(this.configData))
      let data = {
        type: 'shortcut_nav',
        settings: JSON.stringify(this.hasPermissions)
      }
      await this.$api.saveMySetting(data)
    },
    async getUserSetting() {
      let data = {
        type: 'shortcut_nav',
        user_id: this.userInfo.id

      }
      let { info } = await this.$api.getMySetting(data)
      const tableSet = info.settings ? JSON.parse(info.settings) : null
      if (tableSet) this.hasPermissions = tableSet
      console.log(tableSet)
    }
  },
  mounted() {
    this.findPermissionsNav()
    this.getUserSetting()
    // let localShortcutNav = JSON.parse(localStorage.getItem('localShortcutNav'))
    //
    // if (localShortcutNav && localShortcutNav.id === this.userInfo.id) {
    //   this.hasPermissions = localShortcutNav.hasPermissions
    // } else {
    //   localStorage.removeItem('localShortcutNav')
      this.hasPermissions = this.allHasPermissions.slice(0, 12)
    // }

  }
}
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
  padding: 0 !important;
}

::v-deep .el-card__header {
  border-bottom: none;
}

.colClass {
  height: 100px;
  //line-height: 100px;
  //border: 1px solid rgba(0, 0, 0, 0.88);
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  text-align: center;
  cursor: pointer;
  position: relative;

  > span {
    display: block;
    margin-top: 10px;
  }

  .svgCol {
    font-size: 30px;
    display: block;
    margin-top: 20px;
  }

  &:hover {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    transition: 1s;
  }
}

.badge {
  position: absolute;
  right: -4px;
  z-index: 99;
}

::v-deep .el-dialog__header {
  padding: 20px !important;
}
</style>
